<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.76" x2="0.5" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_jobs" transform="translate(-1228 -2768)">
    <g id="icon_park" transform="translate(210)">
     <g data-name="Group 428" id="Group_428" transform="translate(-19 -236)">
      <rect :fill="background" data-name="Rectangle 218" height="183" id="Rectangle_218" transform="translate(1037 3004)" width="183"/>
     </g>
    </g>
    <g data-name="Group 432" id="Group_432" transform="translate(1274.178 2813.976)">
     <path d="M97.306,85.3,78.1,66.091a4.621,4.621,0,0,0-3.276-1.349H71.679a40.06,40.06,0,1,0-6.937,6.937V74.82A4.621,4.621,0,0,0,66.091,78.1L85.3,97.306a4.605,4.605,0,0,0,6.532,0l5.453-5.453a4.646,4.646,0,0,0,.019-6.551ZM40.079,64.742A24.664,24.664,0,1,1,64.742,40.079,24.65,24.65,0,0,1,40.079,64.742Z" data-name="Icon awesome-search" fill="url(#linear-gradient)" id="Icon_awesome-search" transform="translate(0 0)"/>
     <path d="M18.665,18.665a6.333,6.333,0,1,0-6.333-6.333A6.331,6.331,0,0,0,18.665,18.665Zm0,3.166C14.438,21.832,6,23.953,6,28.164v3.166H31.33V28.164C31.33,23.953,22.892,21.832,18.665,21.832Z" data-name="Icon material-person" fill="url(#linear-gradient)" id="Icon_material-person" transform="translate(21.09 19.701)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>